$font-family-sans-serif: 'Montserrat', sans-serif, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family: 'Archivo', sans-serif;
$font-family-headline: "Archivo", serif;

$deep-blue: #002D3F;
$teal: #00BCBC;
$yellow: #F4BA00;
$primary: $deep-blue !default;
$secondary: $teal !default;

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

// 4. Include any optional Bootstrap CSS as needed
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/transitions";

// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "~bootstrap/scss/utilities/api";

@import "spacing";
@import "animation";
@import "~aos/src/sass/aos.scss";

.color-primary {
  color: $teal;
}

.navbar {
  background-color: $deep-blue;
}

.logo {
  height: auto;
  width: 300px;
}

.hero {
  background-color: $deep-blue;

  h1 {
    letter-spacing: 0.7px;
    position: relative;
    z-index: 1;
  }

  .subtitle {
    font-weight: 500;
    font-size: 21px;
    line-height: 35px;
  }

  &-img {
    bottom: -150px;
    width: 75%;
    animation: jumpTwo 6s infinite linear;

    @include media-breakpoint-down(md) {
      bottom: -60px;
    }
  }
}

.inverse {
  p, div {
    color: #ffffff;
  }
}

.btn-tertiary, .btn-secondary {
  text-transform: uppercase;
  font-weight: 600;
  transition: all 0.3s ease-out 0s;
  padding: 12px 40px;
  border-radius: 30px;
  font-size: 16px;

  &:hover {
    background-color: #FFFFFF;
  }
}

.btn-tertiary {
  background-color: $yellow;
  color: $deep-blue;
}

.btn-secondary {
  background-color: $teal;
  color: #FFFFFF;
}

.round-line {
  color: $teal;
  display: inline-block;
  position: relative;
  z-index: -1;

  &::before {
    background: url(../img/br2a.svg) no-repeat;
    content: "";
    height: 120px;
    left: -53px;
    position: absolute;
    top: -25px;
    width: 330px;
    z-index: -1;
    background-size: cover;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

.section-title h3 {
  color: $deep-blue;
  line-height: 1.5;
  position: relative;
  z-index: 1;
  font-weight: 700;

  @include media-breakpoint-down(xl) {
    line-height: 1.2;
  }
}

.section-title-4 h3 {
  font-size: 40px;
}

.section-title-3 h3 {
  font-size: 55px;
}

.section-p {
  font-size: 20px;
  line-height: 35px;
}

section a {
  color: darken($teal, 5%);
  text-decoration: none;
}

.faq-area {
  background-color: #c1e6e7;
}

.accordion-collapse {
  &.collapse.show {
    border-bottom: 5px solid $teal;
  }
}

.accordion-button {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  font-weight: 600;
  font-size: 17px;
}

.dc-text-list {
  li {
    list-style: none;
    margin-bottom: 16px;
    color: $deep-blue;
    padding-left: 40px;
    position: relative;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      background: url(../img/tick.svg) no-repeat;
      width: 19px;
      height: 15px;
      top: 5px;
      left: 0;
      z-index: 1;
      transition: all 0.3s ease-out 0s;
    }
  }
}

footer {
  a, p {
    font-size: 14px;
    color: hsl(197deg 23% 42%) !important;
  }
}

.underline {
  color: $teal;
  padding-bottom: 5px;
  border-bottom: 7px solid $teal;
}

.sub-bg {
  background-color: #E0EDF4;
  border-radius: 25px;

  @include media-breakpoint-down(md) {
    border-radius: 0;
  }
}